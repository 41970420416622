import React from "react";
import broadcast from '../service_assets/broadcast.png';
import { Footer, Navbar } from "../components";
import './radio.css';
import old_man from '../service_assets/old-man.png'
import group from '../service_assets/Group.png'
import massive from '../service_assets/massive.png'
import long_term from '../service_assets/long-term.png'
import onar from '../service_assets/onar.jpg'
import onair from '../service_assets/onair.jpeg'
import coca_cola from '../service_assets/coca-cola.png'

const Radio = () => {
  return (
    <>
    <Navbar/>
      <div className="hero border-1 pb-3">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
               <div className="card-first border-0 mb-4">
               <h1><span className="main-title">Broadcast </span>Your Message with Radio Ads</h1>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card  text-white border-0 mb-4">
                <img
                  className="card-img2 img-fluid"
                  src={broadcast}
                  alt="Card 2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /****2Nd SECTION******** */}
      <div className="container">
     <h1 className="old-man-title">Amplify your brand's voice through the power of radio and connect with a diverse audience.</h1> 
      <div className="old-man reverse">
        <div className="old-man-text">
          <p>
          Radio advertising offers an intimate and highly personal way of reaching your target audience. With millions tuning in daily, radio ads cut through the clutter of digital ads, allowing your message to be heard in a distraction-free environment. Whether it's during a commute, at work, or at home, your brand can resonate with listeners in a powerful way.
          </p>
        </div>
        <div className="old-man-image">
          <img src={old_man} alt="Radio" />
        </div>
      </div>
      </div>

{/**********************THIRD SECTION*****************************/}

 <section className="why-choose-section">
  <div className="container">
    <h2 className="outdoor-title">Why Radio Ads?</h2>
    <div className="row row1">
      <div className="col feature-card">
        <img src={massive} alt="Massive Reach Icon" />
        <h4>Wide Reach</h4>
        <p>Radio reaches 93% of the population every week</p>
      </div>
      <div className="col feature-card">
        <img src={long_term} alt="Long-Term Exposure Icon" />
        <h4>Effective</h4>
        <p>High ROI for advertising with a broad audience.</p>
      </div>
      <div className="col feature-card">
        <img src={group} alt="High Visual Impact Icon" />
        <h4>Targeted Advertising</h4>
        <p>Radio stations have diverse audience demographics.</p>
      </div>
    </div>
  </div>
 </section>

 {/*********************THIRD SECTION**************************/}

      <div className="container" style={{marginTop:'30px'}}>
      <h1 className="outdoor-title">Successful Radio Ad Campaigns</h1>

      <div className="successfull">
        <div className="successfull-text">
          <h2>McDonald's "I'm Lovin' It" Radio Campaign</h2>
          <p>
          McDonald's used its iconic jingle "I'm Lovin' It" to create highly memorable radio ads that resonated with listeners globally. By using simple and catchy slogans combined with localized promotions, the campaign helped McDonald's connect with diverse audiences, reinforcing its brand recognition and increasing foot traffic to restaurants.
          </p>
        </div>
        <div className="successfull-image">
          <img src={onar} alt="Hoardings" />
        </div>
      </div>

      <div className="successfull reverse">
        <div className="successfull-text">
          <h2>Nike "Just Do It" Radio Ad Series</h2>
          <p>
          Nike took its famous "Just Do It" slogan to the airwaves with motivating and inspiring radio ads. These ads focused on individual stories of athletes overcoming obstacles and achieving greatness. By speaking directly to listeners' aspirations, Nike successfully strengthened its brand's association with empowerment and action.s
          </p>
        </div>
        <div className="successfull-image">
          <img src={onair} alt="Radio" />
        </div>
      </div>
      <div className="successfull">
        <div className="successfull-text">
          <h2>McDonald's "I'm Lovin' It" Radio Campaign</h2>
          <p>
          As part of the global "Share a Coke" campaign, Coca-Cola launched radio ads encouraging listeners to share a Coke with friends and family. The personalized and interactive ads helped create a sense of connection and fun, with Coca-Cola seeing a significant boost in both product sales and brand engagement during the campaign.
          </p>
        </div>
        <div className="successfull-image">
          <img src={coca_cola} alt="Hoardings" />
        </div>
      </div>
      </div>
      <div className="call-to-action">
        <h3 className="title-call">Ready to Amplify Your Brand?</h3>
        <p>Let’s start planning your next radio ad campaign today. Whether it’s local or national, we have the expertise to get your brand heard.</p>
        <button className="cta-button">Contact Us</button>
      </div>
     <Footer/>
    </>
  );
};

export default Radio;
