import React from "react";
import { Footer, Navbar } from "../components";
import hoarding1 from '../assets/hoarding1.jpg';
import radio1 from '../assets/radio.gif';
import transit_media from '../assets/transit media.gif';
import './service.css';
import digiads from '../assets/DIGIADS.gif';
import tele from '../assets/TELE.gif';
import branding from '../assets/branding.gif';
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <div>
      <Navbar/>
    <div className="services-section">
      <h1 className="services-title">SERVICES</h1>

      <div className="service">
        <div className="service-text">
          <h2>HOARDINGS</h2>
          <p>
            Hoarding ads are large outdoor advertisements typically displayed on
            construction sites, building facades, and other high-visibility
            areas. They serve as a powerful marketing tool, capturing the attention
            of passersby and creating brand awareness.
          </p>
          <Link to ='/hoardings' className="read-more">READ MORE &gt;&gt;&gt;</Link>
        </div>
        <div className="service-image">
          <img src={hoarding1} alt="Hoardings" />
        </div>
      </div>

      <div className="service radio-service reverse">
        <div className="service-text">
          <h2>RADIO</h2>
          <p>
            Are you looking to amplify your brand's voice and reach a wider
            audience? With our specialized radio ads service, we provide a unique
            platform to connect with potential customers through the airwaves.
          </p>
          <Link to='/radio' className="read-more">READ MORE &gt;&gt;&gt;</Link>
        </div>
        <div className="service-image radio-image">
          <img src={radio1} alt="Radio" />
        </div>
      </div>

      <div className="service">
        <div className="service-text">
          <h2>TRANSIT MEDIA</h2>
          <p>
            Transit media ads amplify your brand's voice and reach audiences
            while they're on the move. Advertise on buses, trains, and taxis to
            gain massive visibility.
          </p>
          <a href="#" className="read-more">READ MORE &gt;&gt;&gt;</a>
        </div>
        <div className="service-image transit-image">
          <img src={transit_media} alt="Transit Media" />
        </div>
      </div>

      <div className="service reverse">
        <div className="service-text">
          <h2>DIGITAL ADS</h2>
          <p>
            Are you looking to amplify your brand's voice and reach a wider audience? With our specialized radio ads service, we provide a unique platform to connect with potential customers through the airwaves.
          </p>
          <a href="#" className="read-more">READ MORE &gt;&gt;&gt;</a>
        </div>
        <div className="service-image">
          <img src={digiads} alt="Radio" />
        </div>
      </div>

      <div className="service">
        <div className="service-text">
          <h2>TELEVISION</h2>
          <p>
            Hoarding ads are large outdoor advertisements typically displayed on
            construction sites, building facades, and other high-visibility
            areas. They serve as a powerful marketing tool, capturing the attention
            of passersby and creating brand awareness.
          </p>
          <a href={hoarding1} className="read-more">READ MORE &gt;&gt;&gt;</a>
        </div>
        <div className="service-image">
          <img src={tele} alt="Hoardings" />
        </div>
      </div>
      <div className="service reverse">
        <div className="service-text">
          <h2>BRANDING</h2>
          <p>
            Are you looking to amplify your brand's voice and reach a wider audience? With our specialized radio ads service, we provide a unique platform to connect with potential customers through the airwaves.
          </p>
          <a href="#" className="read-more">READ MORE &gt;&gt;&gt;</a>
        </div>
        <div className="service-image">
          <img src={branding} alt="Radio" />
        </div>
      </div>
   </div>
    <Footer/>
    </div>
  );
};

export default Services;
