
// import React from "react";
// import './product.css';
// import television from '../assets/television.png';
// import hoardings from '../assets/hoardings.jpg';
// import radio from '../assets/radio.jpg';
// import transit_media from '../assets/transit_media.jpg';
// import advertise from '../assets/advertise.jpg';
// import brand from '../assets/brand.jpg';

// function Product() {
//   return (
//     <div className="container product-container">
//       <div className="row justify-content-center">
//         <div className="card-product gradient-bg">
//           <img src={hoardings} className="card-img-top" alt="hoardings"/>
//           <div className="card-title">Hoardings</div>
//         </div>
//         <div className="card-product gradient-bg">
//           <img src={radio} className="card-img-top" alt="radio"/>
//           <div className="card-title">Radio</div>
//         </div>
//         <div className="card-product gradient-bg">
//           <img src={transit_media} className="card-img-top" alt="transit media"/>
//           <div className="card-title">Transit Media</div>
//         </div>
//         <div className="card-product gradient-bg">
//           <img src={advertise} className="card-img-top" alt="advertise"/>
//           <div className="card-title">Advertise</div>
//         </div>
//         <div className="card-product gradient-bg">
//           <img src={brand} className="card-img-top" alt="brand"/>
//           <div className="card-title">brand</div>
//         </div>
//         <div className="card-product television-gradient">
//           <img src={television} style={{marginLeft:'30px',marginTop:'10px'}} className="television-image" alt="television"/>
//           <div className="card-title">Television</div>
//         </div>
//         <button className="btn-explore">EXPLORE OUR EXPERTISE</button>
//       </div>
//     </div>
//   );
// }

// export default Product;




// import React from 'react';
// import './product.css';
// import television from '../assets/television.png';
// import hoardings from '../assets/hoardings.jpg';
// import radio from '../assets/radio.jpg';
// import transit_media from '../assets/transit_media.jpg';
// import advertise from '../assets/advertise.jpg';
// import brand from '../assets/brand.jpg';

// const expertiseItems = [
//   { id: 1, title: 'Hoardings', img: hoardings },
//   { id: 2, title: 'Radio', img: radio },
//   { id: 3, title: 'Transit Media', img: transit_media },
//   { id: 4, title: 'Advertise', img: advertise },
//   { id: 5, title: 'Brand', img: brand },
//   { id: 6, title: 'Television', img:television },
// ];

// const Product = () => {
//   return (
//     <div className='container'>
//     <section className="expertise-section" >
//       <div className="expertise-grid">
//         {expertiseItems.map((item) => (
//           <div key={item.id} className="expertise-item">
//             <img src={item.img} alt={item.title} />
//             <div className="overlay">{item.title}</div>
//           </div>
//         ))}
//       </div>
//       <div className="explore-button">
//         <button>EXPLORE OUR EXPERTISE</button>
//       </div>
//     </section>
//     </div>
//   );
// };

// export default Product;



import React from 'react';
import './product.css';
import television from '../assets/television.png';
import hoardings from '../assets/hoardings.jpg';
import radio from '../assets/radio.jpg';
import transit_media from '../assets/transit_media.jpg';
import advertise from '../assets/advertise.jpg';
import brand from '../assets/brand.jpg';

const expertiseItems = [
  { id: 1, title: 'Hoardings', img: hoardings },
  { id: 2, title: 'Radio', img: radio },
  { id: 3, title: 'Transit Media', img: transit_media },
  { id: 4, title: 'Advertise', img: advertise },
  { id: 5, title: 'Brand', img: brand },
];

const Product = () => {
  return (
    <div className='container'>
      <section className="expertise-section">
        <div className="expertise-grid">
          {/* Map through the expertiseItems */}
          {expertiseItems.map((item) => (
            <div key={item.id} className="expertise-item">
              <img src={item.img} alt={item.title} />
              <div className="overlay bottom-left">{item.title}</div>
            </div>
          ))}

          {/* Add the television image separately with custom styles */}
          <div className="television-gradient">
            <img src={television} alt="Television" className="television-image" />
            <div className="overlay">Television</div>
          </div>
        </div>
        <div className="explore-button">
          <button>EXPLORE OUR EXPERTISE</button>
        </div>
      </section>
    </div>
  );
};

export default Product;
