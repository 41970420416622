// import React from "react";
// import project from '../assets/project.gif'
// import industries from '../assets/industries.gif'
// import clients from '../assets/client.gif'
// import years from '../assets/deadline.gif'
// import './category.css';
// function Category(){
//     return(
//         <div className="container">
//             <div className="row">
//            <div className="card-gif" style={{width: "17rem"}}>
//           <img className="card-img-top category-img" src={project} alt="Card image cap"/>
//          <div className="card-body">
//           <h5 className="card-title">100+</h5>
//          <p className="card-text">PROJECTS</p>
//       </div>
//      </div>
//      <div className="card-gif" style={{width: "17rem"}}>
//           <img className="card-img-top category-img" src={industries} alt="Card image cap"/>
//          <div className="card-body">
//           <h5 className="card-title">10+</h5>
//          <p className="card-text">INDUSTRIES</p>
//       </div>
//      </div>
//      <div className="card-gif" style={{width: "17rem"}}>
//           <img className="card-img-top category-img" src={clients} alt="Card image cap"/>
//          <div className="card-body">
//           <h5 className="card-title">120+</h5>
//          <p className="card-text">CLIENTS</p>
//       </div>
//      </div>
//      <div className="card-gif" style={{width: "17rem"}}>
//           <img className="card-img-top category-img" src={years} alt="Card image cap"/>
//          <div className="card-body">
//           <h5 className="card-title">50+</h5>
//          <p className="card-text">YEARS</p>
//       </div>
//      </div>
// </div>
// </div>
//  )
// }
// export default Category;

/****************RESPONSIVE STYLE */
import React from "react";
import project from '../assets/project.gif';
import industries from '../assets/industries.gif';
import clients from '../assets/client.gif';
import years from '../assets/deadline.gif';
import './category.css';

function Category() {
  return (
    <div className="category-container">
      <div className="category-row">
        <div className="card-gif">
          <img className="category-img" src={project} alt="Projects"/>
          <div className="card-body">
            <h5 className="gif-title">100+</h5>
            <p className="card-text">PROJECTS</p>
          </div>
        </div>
        <div className="card-gif">
          <img className="category-img" src={industries} alt="Industries"/>
          <div className="card-body">
            <h5 className="gif-title">10+</h5>
            <p className="card-text">INDUSTRIES</p>
          </div>
        </div>
        <div className="card-gif">
          <img className="category-img" src={clients} alt="Clients"/>
          <div className="card-body">
            <h5 className="gif-title">120+</h5>
            <p className="card-text">CLIENTS</p>
          </div>
        </div>
        <div className="card-gif">
          <img className="category-img" src={years} alt="Years"/>
          <div className="card-body">
            <h5 className="gif-title">50+</h5>
            <p className="card-text">YEARS</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Category;
