// import React from 'react';
// import man1 from '../assets/man1.jpg';
// import './testimonial.css';

// function TestimonialCard() {
//     return (
//         <div className="testimonial-card">
//             <div className="testimonial-img-wrapper">
//                 <img src={man1} alt="Person" className="testimonial-img" />
//             </div>
//             <h2 className="testimonial-name">Atharv Sangale</h2>
//             <h4 className="testimonial-title">CEO of XYZ Industries</h4>
//             <p className="testimonial-text">
//                 <span className="quote-left"></span> Working with this advertising team has transformed our brand visibility! 
//                 They crafted innovative campaigns that truly resonate with our audience. Highly recommend their creative approach! 
//                 <span className="quote-right"></span>
//             </p>
//             </div>
//     );
// }

// export default TestimonialCard;


import React from 'react';
import Slider from 'react-slick';
import man1 from '../assets/man1.jpg';
import man2 from '../assets/man2.jpg';
import man3 from '../assets/man3.jpg';
import './testimonial.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const testimonials = [
    {
        img: man1,
        name: 'Atharv Sangale',
        title: 'CEO of XYZ Industries',
        text: 'Working with this advertising team has transformed our brand visibility! They crafted innovative campaigns that truly resonate with our audience.'
    },
    {
        img: man2,
        name: 'John Doe',
        title: 'Marketing Manager at ABC Corp',
        text: 'This team went above and beyond in delivering an exceptional advertising strategy. Their creativity and attention to detail were top-notch!'
    },
    {
        img: man3,
        name: 'Jane Smith',
        title: 'Creative Director at DEF Studio',
        text: 'The collaboration with this team has been nothing short of amazing. Their insights and ability to capture our vision was impressive!'
    }
];

function TestimonialCarousel() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2, 
        slidesToScroll: 1,
        autoplay: true, 
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 768, 
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
        ],
    };
    

    return (
        <div className='container'>
            <h2 className="title happy-title" style={{marginTop:"70px"}}>OUR HAPPY CLIENTS</h2>
        <div className="testimonial-carousel">
            <Slider {...settings}>
                {testimonials.map((testimonial, index) => (
                    <div key={index} className="testimonial-card">
                        <div className="testimonial-img-wrapper">
                            <img src={testimonial.img} alt={testimonial.name} className="testimonial-img" />
                        </div>
                        <h2 className="testimonial-name">{testimonial.name}</h2>
                        <h4 className="testimonial-title">{testimonial.title}</h4>
                        <p className="testimonial-text">
                            {testimonial.text}
                        </p>
                    </div>
                ))}
            </Slider>
        </div>
        </div>
    );
}

export default TestimonialCarousel;
