import React from "react";
import { Footer, Navbar } from "../components";
import './page1.css'
import outdoor from '../service_assets/outdoor.png'
import massive from '../service_assets/massive.png'
import long_term from '../service_assets/long-term.png'
import group from '../service_assets/Group.png'
import construction from '../service_assets/construction.jpg'
import building from '../service_assets/building.png'
import billboard from '../service_assets/billboard.png'
const Page1 = () => {
  return (
    <>
    <Navbar/>
    {/*************IMAGE SECTION********************/}
      <div className="hero border-1 pb-3">
        <div className="card text-black border-0 mx-3">
          <img
            className="card-img img-fluid"
            src={outdoor}
            alt="Card"
            height={500}
          />
        </div>
      </div>
        {/*************TEXT SECTION********************/}
        <section className="power-section">
         <div className="container">
           <p className="lead">
             Discover the power of large-format advertisements in capturing attention and driving engagement.
           </p>
           <p className="lead-p">
             Outdoor media includes billboards, construction site banners, building facades, and more. These large-scale advertisements are
             strategically placed in high-traffic areas to maximize visibility. They are an impactful way to elevate brand awareness,
             create brand recognition, and generate leads.
           </p>
         </div>
       </section>
  
   {/*************why-choose SECTION********************/}
   <section className="why-choose-section">
  <div className="container">
    <h2 className="outdoor-title">Why Choose Outdoor Media?</h2>
    <div className="row1">
      <div className="col feature-card"> 
        <img src={massive} alt="Massive Reach Icon" />
        <h4>Massive Reach</h4>
        <p>Placed in high-traffic areas, they reach thousands daily.</p>
      </div>
      <div className="col feature-card">
        <img src={long_term} alt="Long-Term Exposure Icon" />
        <h4>Long-Term Exposure</h4>
        <p>Often seen by commuters on a daily basis.</p>
      </div>
      <div className="col feature-card">
        <img src={group} alt="High Visual Impact Icon" />
        <h4>High Visual Impact</h4>
        <p>Large visuals leave a lasting impression.</p>
      </div>
    </div>
  </div>
 </section>
     
   {/*************TYPES OF OUTDOOR SECTION********************/}
      <div className="container">
      <h1 className="outdoor-title">Types of Outdoor Media</h1>
      <div className="outdoor">
        <div className="outdoor-text">
          <h2>Construction Hoardings:</h2>
          <p>
          Large ads placed around construction sites, these hoardings are perfect for targeting foot traffic and drivers alike.
          </p>
        </div>
        <div className="outdoor-image">
          <img src={construction} alt="Construction Hoardings" />
        </div>
      </div>
      <div className="outdoor reverse">
        <div className="outdoor-text">
          <h2>Building Facade Hoardings:</h2>
          <p>
          Ads that cover entire sides of buildings, offering a massive canvas for brand storytelling.
          </p>
        </div>
        <div className="outdoor-image">
          <img src={building} alt="Building Facade Hoardings" />
        </div>
      </div>
      <div className="outdoor">
        <div className="outdoor-text">
          <h2>Billboard Hoardings:</h2>
          <p>
          Strategically placed in high-traffic areas, these hoardings reach a broad audience of commuters.
          </p>
        </div>
        <div className="outdoor-image">
          <img src={billboard} alt="Construction Hoardings" />
        </div>
      </div>
     </div>
   
     {/*************LAST SECTION********************/}


   <section className="ftco-section1 ftco-category1 ftco-no-pt1">
 			<div className="container-animal">
        <h1 className="outdoor-title">Our Outdoor Media  Installations</h1>
			<div className="row">
 					<div className="col-md-8">						
            <div className="row">
              <div className="col-md-6">
 								<div className="category-wrap1 img1 mb-4 d-flex align-items-end ftco-animated" style={{ backgroundImage: `url(${require('../service_assets/image1.jpg')})` }}>
 								</div> 								
                <div className="category-wrap1 img1 d-flex align-items-end" style={{ backgroundImage: `url(${require('../service_assets/image4.jpg')})` }}>
 								</div>
                 <div className="category-wrap1 img1 mb-4 img1 d-flex align-items-end  mt-4" style={{ backgroundImage: `url(${require('../service_assets/onair.jpeg')})` }}>
 								</div>
 							</div>
 						
            
             <div className="col-md-6 col2">
 								<div className="category-wrap1 img1 mb-4 d-flex align-items-end" style={{ backgroundImage: `url(${require('../service_assets/image2.jpeg')})` }}>
 								</div> 
                <div className=" card1 category-wrap1 d-flex align-items-center justify-content-center" style={{ backgroundColor: '#fff',padding: '2em' }}>
                 <div className="text-center">
                <h3>Ready to Make Your Brand Stand Out?</h3>
                  <button className="btn2 btn-lg">Contact Us</button>
                    </div>
                </div>
              <div className="category-wrap1 img1 mb-4 d-flex align-items-end mt-4" style={{         backgroundImage: `url(${require('../service_assets/image6.jpeg')})` }}>
 								</div>
 							</div>
 						</div>
            </div>

          <div className="col-md-4">
 						<div className="category-wrap1 img1 mb-4 d-flex align-items-end" style={{ backgroundImage: `url(${require('../service_assets/image3.jpeg')})` }}>		
 						</div>
 						<div className="category-wrap1  img1 d-flex align-items-end " style={{ backgroundImage: `url(${require('../service_assets/image5.jpeg')})`}}>
						</div>
            <div className="category-wrap1 img1 d-flex align-items-end mt-4" style={{ backgroundImage: `url(${require('../service_assets/image7.jpeg')})` }}>
 								</div>
 					</div>
 				</div>
 			</div>
 		</section>
   <Footer/>
    </>
  );
};

export default Page1;
