// import React, { useState, useEffect } from "react";
// import './we_are.css';

// function WeAre() {
//   const subtitles = [
//     "Growth",
//     "Innovative",
//     "Creative",
//     "Responsible",
//     "Creative",
//   ];

//   const [currentSubtitleIndex, setCurrentSubtitleIndex] = useState(0);
//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setCurrentSubtitleIndex((prevIndex) => (prevIndex + 1) % subtitles.length);
//     }, 3000); 

//     return () => clearInterval(intervalId);
//   }, [subtitles.length]);

//   return (
//     <div className="container-we_are">
//       <h1 className="title1">
//         We Are&nbsp;
//         <span className={`subtitle animate`}>{subtitles[currentSubtitleIndex]}</span>
//       </h1>
//     </div>
//   );
// }

// export default WeAre;

/***********************************/

import React from "react";
import './we_are.css';

function WeAre() {
  return (
    <div className="container-we_are">
      <h1 className="title-we_are">We Are&nbsp;</h1>
      <div className="subtitle-wrapper">
        <div className="subtitle-container">
          <h2 className="subtitle">Growth</h2>
          <h2 className="subtitle">Creative</h2>
          <h2 className="subtitle">Innovative</h2>
          <h2 className="subtitle">Responsible</h2>
          <h2 className="subtitle">Progressive</h2>
        </div>
      </div>
    </div>
  );
}

export default WeAre;
