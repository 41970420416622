// import React from "react";
// import './firstContent.css';
// import icon from '../assets/icon.png';

// function FirstContent() {
//     return (
//             <div className="container-fluid">
//                         <div className="pen-icon">
//                             <img src={icon} alt="Pen Icon" className="img-fluid" />
//                         </div>
//                 <div className="row align-items-center justify-content-center">
//                     <div className="col-auto" style={{marginBottom:"50px",marginTop:'0px'}}>
//                         <div className="text-box">
//                             <p>Unlock Your Brand's Potential with<br />Cutting-Edge Digital Marketing!</p>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//     );
// }

// export default FirstContent;


import React from "react";
import './firstContent.css';
import icon from '../assets/icon.png';

function FirstContent() {
    return (
        <div className="container-fluid">
            <div className="pen-icon">
                <img src={icon} alt="Pen Icon" className="img-fluid" />
            </div>
            <div className="row align-items-center justify-content-center">
                <div className="col-lg-8 col-md-10 col-sm-12 text-box-wrapper">
                    <div className="text-box">
                        <p>Unlock Your Brand's Potential with<br />Cutting-Edge Digital Marketing!</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FirstContent;
