import React from "react";
import './believe.css'; 

function Believe() {
  return (
    <div className="container">
      <h2 className="title">WHAT WE BELIEVE</h2>
      <div className="text">
        <p>
          At SanAds, we believe in the transformative power of effective communication. Our mission is to bridge the gap
          between brands and their audiences by crafting compelling marketing strategies that resonate, inspire, and drive
          success. With a commitment to innovation and a deep understanding of both traditional and digital landscapes,
          we empower our clients to achieve their fullest potential and build lasting connections with their customers.
        </p>
      </div>
    </div>
  );
}

export default Believe;
