// import React from "react";

// const Footer = () => {
//   return (
//     <>
//       <footer className="mb-0 text-center">
//         <div className="d-flex align-items-center justify-content-center pb-5">
//           <div className="col-md-6">
//             <a className="text-dark fs-4" href="https://github.com/ssahibsingh" target="_blank" rel="noreferrer">
//             </a>
//           </div>
//         </div>
//       </footer>
//     </>
//   );
// };

// export default Footer;

import React from 'react';
import logo from '../assets/samads.jpeg'
import linkedin from '../assets/linkedin.png'
import instagram from '../assets/instagram.png'
import facebook from '../assets/facebook.png'
import youtube from '../assets/youtube.png'
import twitter from '../assets/twitter.png'
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <img src={logo} alt="Logo" className="footer-logo" />
          <p>
            At SAN ADS, we believe that every brand has a unique story to tell. 
            Our mission is to bring that story to life through innovative campaigns 
            tailored to your specific needs.
          </p>
        </div>
        <div className="footer-column">
          <h3>SERVICES</h3>
          <ul>
            <li>Hoardings</li>
            <li>Radio</li>
            <li>Transit Media</li>
            <li>Television</li>
            <li>Digital Advertising</li>
            <li>Branding</li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>ADDRESS</h3>
          <ul>
            <li>5, First Floor, Vanraj Jasmine, V. N. Naik College Road, New Pandit Colony, Gangapur Road, Nashik - 422005</li>
            <li>Contact Us: +91 1234567890</li>
            <li>Email us: sanads@gmail.com</li>
          </ul>
          <div className="social-icons">
            <a href="#"><img src={linkedin} alt="LinkedIn" style={{height:'30px',width:'30px'}}/></a>
            <a href="#"><img src={instagram} alt="Instagram" style={{height:'35px',width:'35px'}}/></a>
            <a href="#"><img src={facebook} alt="Facebook" style={{height:'30px',width:'30px'}} /></a>
            <a href="#"><img src={youtube} alt="YouTube" style={{height:'35px',width:'35px'}}/></a>
            <a href="#"><img src={twitter} alt="Twitter" style={{height:'32px',width:'32px'}}/></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
