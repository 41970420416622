import React from "react";
import './quotes.css';

function Quotes() {
  return (
    <div className="quote-container">
      <p className="quote-text">
        Communication leads to community, that is, to understanding, intimacy, and mutual valuing.
      </p>
      <p className="quote-author">— Rollo May</p>
    </div>
  );
}

export default Quotes;
