import { Navbar, Main, Product, Footer } from "../components";
import Believe from "../components/Believe";
import Category from "../components/Category";
import ConnectNow from "../components/ConnectNow";
import FirstContent from "../components/FirstContent";
import OurPromise from "../components/OurPromise";
import Quotes from "../components/Quotes";
import TestimonialCard from "../components/TestimonialCard";
import WeAre from "../components/WeAre";

function Home() {
  return (
    <>
      <Navbar />
      <FirstContent/>
      <Main />
      <Believe/>
      <Product />
      {/* <Category1/> */}
      <Quotes/>
      <Category/>
      <WeAre/>
      <OurPromise/>
      <TestimonialCard/>
      <ConnectNow/>
      <Footer />
    </>
  )
}

export default Home