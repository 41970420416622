// import React from "react";
// import sanads3 from '../assets/sanads3.jpg'
// const Home = () => {
//   return (
//     <>
//       <div className="hero border-1 pb-3">
//         <div className="card bg-dark text-white border-0 mx-3">
//           <img
//             className="card-img img-fluid"
//             src={sanads3}
//             alt="Card"
//             height={500}
//           />
//           <div className="card-img-overlay d-flex align-items-center">
//             <div className="container">
//               <h5 className="card-title fs-1 text fw-lighter">New Season Arrivals</h5>
//               <p className="card-text fs-5 d-none d-sm-block ">
//                 This is a wider card with supporting text below as a natural
//                 lead-in to additional content. This content is a little bit
//                 longer.
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Home;

import React from "react";
import sanads3 from '../assets/sanads3.jpg';
import digisan from '../assets/digisan.jpg'; 
import './main.css';

const Home = () => {
  return (
    <>
      <div className="hero border-1 pb-3">
        <div className="container">
          <div className="row">
            {/* First Column */}
            <div className="col-md-6">
              <div className="card bg-dark text-white border-0 mb-4">
                <img
                  className="card-img img-fluid"
                  src={sanads3}
                  alt="Card 1"
                  style={{ height: '700px', objectFit: 'cover' }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="card  text-white border-0 mb-4">
                <img
                  className="card-img2 img-fluid"
                  src={digisan}
                  alt="Card 2"
                  // style={{ height: '100%',width:'95%',objectFit: 'cover',marginTop:'70px' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
