import React from "react";
import './believe.css'; 

function OurPromise() {
  return (
    <div className="container">
      <h2 className="title">OUR PROMISE</h2>
      <div className="text">
        <p>
            Quality is not just a goal,  it's our promise. We meticulously oversee every project from start to finish, ensuring that your
            advertising  not only meets but exceeds your expectations. With SANADS, you can trust  that your brand is in capable hands, 
            delivering exceptional results that drive success.
        </p>
      </div>
    </div>
  );
}

export default OurPromise;
